<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">
          <span >{{$t('DEBITORCONTACT.HEADER.TITEL')}}</span>
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span >{{$t('DEBITORCONTACT.HEADER.TEXT')}}</span>
        </p>
        <AddDebitorContactDlg ref="addDebitorContactDlg" :SelectDebAndObjOnly="false"></AddDebitorContactDlg>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="$refs.addDebitorContactDlg.showDlg()" >{{$t('DEBITORCONTACT.ADD')}}</vs-button>
            </div> 
            <div id="debitorcontacts_table" class="vs-con-loading__container">
              <vs-table
                search
                :data="debitorcontacts"
                :noDataText="$t('DEBITORCONTACT.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORCONTACT.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="debitor.debitor.lastname">
                    {{$t('DEBITORCONTACT.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="debitorobject.debitorobject.street1">
                    {{$t('DEBITORCONTACT.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="debitorcontact.created">
                    {{$t('DEBITORCONTACT.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="debitorcontact.last_change">
                    {{$t('DEBITORCONTACT.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="debitorcontact.state">
                    {{$t('DEBITORCONTACT.TABLE.COL5')}}
                  </vs-th>    
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="tr.debitor.debitor">
                      {{GetNameOrCompanyFromDebitor(tr.debitor.debitor)}}<br/>
                      {{tr.debitor.debitor.tel1}}<br/>
                      {{tr.debitor.debitor.email}}
                    </vs-td>  
                    <vs-td :data="tr.debitorobject.debitorobject">
                      {{tr.debitorobject.debitorobject.name}}<br/>
                      {{tr.debitorobject.address1.street1}} {{tr.debitorobject.address1.street2}}<br/>
                      {{tr.debitorobject.address1.plz}} {{tr.debitorobject.address1.city}}, {{GetNameOfCountry(tr.debitorobject.address1.country_fk)}}
                    </vs-td> 
                    <vs-td :data="tr.debitorcontact.created">
                      {{DateToString(tr.debitorcontact.created,false)}}
                    </vs-td>                    
                    <vs-td :data="tr.debitorcontact.last_change">
                      {{DateToString(tr.debitorcontact.last_change,false)}}
                    </vs-td>   
                    <vs-td :data="tr.debitorcontact.state">
                       <vs-chip :color="GetContactMemberStateColor(tr.debitorcontact.state)">{{GetContactMemberStateText(tr.debitorcontact.state)}}</vs-chip>
                       <vs-chip v-show="tr.debitorcontact.fromInfoworx" color="blue">infoworx</vs-chip>
                    </vs-td>                        
                    <vs-td>
                      <vs-button class="ml-2" size="small" color="primary" type="filled" icon="edit" @click="ShowDebitorContact(tr.debitorcontact.id)" ></vs-button>                      
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>           
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import AddDebitorContactDlg from './components/dialogs/AddDebitorContactDlg';
import staticCodeHelper from '../helper/staticCodeHelper';
import enumHelper from '../helper/enumHelper';

export default {
  name: "DebitorContacts",
  components: {
    AddDebitorContactDlg,
  },
    data: function (){
      return {
      debitorcontacts:[],
      popupAddDebitorActive:false
      };
  },
  created () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.debitorcontact.debitorcontacts.data != null)
      {
        data = this.$store.state.debitorcontact.debitorcontacts.data;
      }
      return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.debitorcontact.debitorcontacts.status) != 'undefined')
        loading = this.$store.state.debitorcontact.debitorcontacts.status.loading;
      return loading;
    }
  },
  watch: {
      getTableData(value) {
          this.debitorcontacts = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#debitorcontacts_table'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#debitorcontacts_table'});
        }
      }
    },
  methods: {    
      ...helperMethods,
      ...staticCodeHelper,
      ...enumHelper,
      LoadData: function ()
      {
        this.$store.dispatch('debitorcontact/getMyDebitorContacts',{ root: true },{ root: true }); 
      },
      AddDebitorAccepted(){
        this.LoadData();
      },
      ShowDebitorContact(id)
      {
        this.$router.push({ name: 'DebitorContact', params: { debitorcontact_fk: id } });
      },
  }
};

</script>