<template>
  <div>
    <div class="vs-component vs-input inputx vs-input-primary">
      <label v-if="Label!=''" for="myDatePicker" class="vs-input--label">{{Label}}</label>
      <div class="vs-con-input">
        <div class="d-flex">
          <datetime v-if="DateAsNum > 0" ref="myDatePicker" :title="PopUpTitel" auto :type=pickerType v-model="content" @input="updateDate" :phrases="OkCancelLabel" :use-utc="true"></datetime>
          <input v-if="DateAsNum <= 0" type="text" class="vs-inputx vs-input--input normal" disabled>
          <vs-button  @click="SetToNow()" size="small" color="primary" type="filled" class="ml-2 mr-3">
                <vs-icon icon="today"></vs-icon>
          </vs-button>    
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "DatePicker",
  props:['OkCancelLabel','PopUpTitel','value','Label','Type'],
   data: function (){
     return {
       content: this.InitDate(this.value)
     }
  },
  computed:{
    DateAsNum(){
      return Date.parse(this.content);
    },
    pickerType(){
      var retVal = "datetime";

      if(typeof(this.Type) != 'undefined')
      {
        retVal = this.Type;
      }
      
      return retVal;
    }
  },
  methods:{
    updateDate() {
      this.$emit('input', this.content)
    },
    SetToNow(){ 
      this.content = this.GetNow();
    },
    GetNow(){
      var curDate = new Date();
      return curDate.toISOString();
    },
    InitDate(newVal){
      var retVal = newVal;
      if(typeof(newVal) == 'undefined' || newVal == null || newVal == "" || newVal == {})
      {
        retVal = this.GetNow();
      }
      else
      {
        var datetime = new Date(newVal);
        var offset = new Date().getTimezoneOffset();
        datetime.setMinutes(datetime.getMinutes() - offset);
        retVal = datetime.toISOString();
      }
      return retVal;
    }
  },
  watch:{
    value(newVal){
      this.content = newVal;
    }
  }
};
</script>
<style>
.vdatetime-input{
    color: inherit;
    position: relative;
    padding: .4rem;
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.2);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0,.15);
    box-shadow: 0 0 0 0 rgba(0,0,0,.15);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    width: 100%;
}

.vdatetime-popup__header,
.vdatetime-calendar__month__day--selected > span > span,
.vdatetime-calendar__month__day--selected:hover > span > span {
  background: var(--primary);
}

.vdatetime-year-picker__item--selected,
.vdatetime-time-picker__item--selected,
.vdatetime-month-picker__item--selected,
.vdatetime-popup__actions__button {
  color: var(--primary);
}
</style>